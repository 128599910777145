// {
//   "id": 58,
//   "appliId": "728896153257201664",
//   "appname": "???2",
//   "createtime": 1672390385172,
//   "updatetime": 1672390385172,
//   "path": "",
//   "agentId": "718307985058684928",
//   "status": "pathError",
//   "mediasoupIp": "222.128.2.68",
//   "mediasoupPort": 19803,
//   "mediasoupIpWan": "",
//   "mediasoupPortWan": 0,
//   "autoRestart": 0,
//   "dllPath": "",
//   "dllReady": 0,
//   "appType": 0,
//   "del": 0,
//   "userId": "",
//   "password": null,
//   "userNum": 1,
//   "defaultProjectId": null,
//   "defaultSceneContentId": null,
//   "defaultSceneProjectName": null,
//   "appRenderType": 1,
//   "renderWidth": 1920,
//   "renderHeight": 1040,
//   "appUseType": 1
// }
// {
//   "agentId": "718307985058684928",
//   "appType": 0,
//   "appname": "新联村2",
//   "autoRestart": 0,
//   "dllPath": "",
//   "mediasoupIp": "222.128.2.68",
//   "mediasoupIpWan": "",
//   "mediasoupPort": 19803,
//   "mediasoupPortWan": 0,
//   "path": "",
//   "userId": ""
// }

import cloud from "zhyz-cloudrender-v4/cloudRender.umd";
import { arrayUtil } from "../uitl";
function GetRequest() {
  //url例子：www.baidu.com?id="123456"&name="www"；
  let url = decodeURI(window.location.href); //?id="123456"&name="www";
  const object = {};
  if (url.indexOf("?") != -1) {
    //url中存在问号，也就说有参数。
    // let str = url.substr(1); //得到?后面的字符串
    let str = url.substring(url.indexOf("?") + 1);
    let strs = str.split("&"); //将得到的参数分隔成数组[id="123456",name="www"];
    for (let i = 0; i < strs.length; i++) {
      object[strs[i].split("=")[0]] = strs[i].split("=")[1]; //得到{id:'123456',name:'www'}
    }
  }
  return object;
}
// let appliId = "718311289856413696";
let appliId = "718311289856413696";

if (GetRequest().room == 2) {
  appliId = "728896153257201664";
}
// let appliId = "718311289856413696"; // 云渲染节点中的某个容器的ID
// // "agentId": "718307985058684928",
// // let myhostname = "222.128.2.68"; // 云渲染节点的流媒体地址
// let myhostname = "pass.smartyunzhou.com"; // 云渲染节点的流媒体地址
// // let paasUrl = "http://222.128.2.68:19802"; // paas服务对应地址
// let paasUrl = "https://pass.smartyunzhou.com:443/";
// // let protooPort = '19850';  // 云渲染节点的流媒体端口(非必传参数默认8888)
// let protooPort = "443"; // 云渲染节点的流媒体端口(非必传参数默认8888)
// let username = "admin";
// let password = "syz@8015";
// let appliId = "728896153257201664"; // 云渲染节点中的某个容器的ID
// let appliId = "728896153257201664";
// "agentId": "718307985058684928",
// let myhostname = "222.128.2.68"; // 云渲染节点的流媒体地址
let myhostname = "pass.smartyunzhou.com"; // 云渲染节点的流媒体地址
// let myhostname = "223.108.178.130";
// let paasUrl = "http://222.128.2.68:19802"; // paas服务对应地址
let paasUrl = "https://pass.smartyunzhou.com:443/";
// let paasUrl = "http://223.108.178.130:19802/";
// let protooPort = "19850"; // 云渲染节点的流媒体端口(非必传参数默认8888)
let protooPort = "443"; // 云渲染节点的流媒体端口(非必传参数默认8888)

let username = "admin";
let password = "syz@8015";

// export const Dandelion = new cloud(paasUrl, username, password, true);
export const Dandelion = new cloud(paasUrl, username, password, true, appliId);

//给对象注册点击事件
export const registerClick = (params, callback) => {
  Dandelion.RtAPI("registerWebscoket", (res) => {
    let data = JSON.parse(res);
    // console.log(data);
    callback(data);
  });
  // let params2 = {area: false,contour: false,coordinate: false,data: false,effect: false,fence: false,heatmap: false,model: false,path: false,pixelPoint: false,poi: false,river: false,vegetation: false,video: false};
  let params2 = {
    area: false,
    contour: false,
    coordinate: false,
    data: false,
    effect: false,
    fence: false,
    heatmap: false,
    model: false,
    path: false,
    pixelPoint: false,
    poi: false,
    river: false,
    vegetation: false,
    video: false,
  };
  for (let key in params) {
    if (!Object.prototype.hasOwnProperty.call(params2, key))
      throw new Error("给不存在的对象注册了点击事件");
    params2[key] = params[key];
  }
  Dandelion.RtAPI("switchCallback", params2, () => {
    // console.log(res);
  });
};
//获取poi详情
export const getPoiDetail = async (id) => {
  // reject
  return new Promise((resolve) => {
    Dandelion.RtAPI("getPoi", { id }, (res) => {
      resolve(res);
    });
  });
};

//获取当前视点
export const getViewPoint = () => {
  return new Promise((resolve) => {
    Dandelion.RtAPI("getViewPoint", { home: true }, (res) => {
      // console.log(JSON.stringify(res));
      resolve(res);
    });
  });
};

// {"home": false,
// "height": 81.87525177001953,
// "latitude": 32.918704986572266,
// "longitude": 119.57603454589844,
// "heading": -1.640492007777101,
// "pitch": -43.19068231261825,
// "range": 5721.30241628324,
// "throughtime": 0,
// "vaild": true
// }
//设置默认视点
export const setDefaultViewPoint = (params) => {
  return new Promise((resolve) => {
    Dandelion.RtAPI("setDefaultViewPoint", params, (res) => {
      // console.log(123213123)
      resolve(res);
    });
  });
};

//跳转到某一个对象上
export const locationSceneObject = (params) => {
  return new Promise((resolve) => {
    Dandelion.RtAPI("locationSceneObject", params, (res) => {
      resolve(res);
    });
  });
};

//加载场景
export const joinRoom = (boxId) => {
  Dandelion.RtAPI("joinRoom", {
    ele: document.querySelector("#" + boxId), //指定的挂载三维场景的页面元素
    myhostname,
    appliId,
    protooPort,
  });
};

//回到默认视点
export const toHome = () => Dandelion.RtAPI("backToOrigin");

export const narrow = (res) =>
  Dandelion.RtAPI("narrow", { offsetX: res.x, offsetY: res.y });

export const enlarge = (res) =>
  Dandelion.RtAPI("enlarge", { offsetX: res.x, offsetY: res.y });

//更新视点
export const updateViewPoint = (params) => {
  return new Promise((resolve) => {
    Dandelion.RtAPI("updateViewPoint", params, (res) => {
      resolve(res);
    });
  });
};

//控制显影
export const displayObject = (params) => {
  return new Promise((resolve) => {
    Dandelion.RtAPI("displayObject", params, (res) => {
      resolve(res);
    });
  });
};

//获取poi列表
const { isIntersection } = arrayUtil;
export const getPoiList = (params) => {
  return new Promise((resolve) => {
    Dandelion.RtAPI("getPoiList", { view: false }, (list) => {
      let data = [];
      if (params && params.tags && params.tags.length) {
        console.log(list);
        for (let res of list) {
          if (isIntersection(res.tags, params.tags)) {
            data.push(res);
          }
        }
      } else {
        data = list;
      }
      resolve(data);
    });
  });
};
//更新 poi
export const updatePoi = (params) => {
  return new Promise((resolve) => {
    Dandelion.RtAPI("updatePoi", params, (res) => {
      // console.log(res);
      resolve(res);
    });
  });
};
//新增poi
export const addPoi = (params) => {
  return new Promise((resolve) => {
    Dandelion.RtAPI("addPoi", params, (res) => {
      // console.log(res);
      resolve(res);
    });
  });
};
//删除poi
export const deletePoi = (params) => {
  return new Promise((resolve) => {
    Dandelion.RtAPI("deletePoi", params, (res) => {
      // console.log(res);
      resolve(res);
    });
  });
};
export const getTags = () => {
  Dandelion.RtAPI("getTags", (res) => {
    debugger;
    console.log(res);
  });
};
//新增数据面片
export const addDataPannel = async (url, params, tag, dheight, dwidth) => {
  for (let param of params) {
    let s = [];
    s.push(
      new Promise((resolve) => {
        Dandelion.RtAPI(
          "addDataPannel",
          {
            backBlend: tag == "随手拍" ? false : true,
            // backBlend: true,
            billBoard: true,
            bindID: "",
            dheight: dheight, //5400, //1080
            dwidth: dwidth, //9600, //1920
            globalState: true,
            name: "",
            position: {
              height: 10,
              latitude: param.latitude,
              longitude: param.longitude,
            },
            tags: [tag],
            url: `${url}?one=${encodeURIComponent(JSON.stringify(param))}`,
            // url:'http://www.smartyunzhou.com/',
            viewMaxRange: 1000000,
            viewMinRange: 0,
          },
          (res) => {
            resolve(res);
          }
        );
      })
    );
  }
  let res = await Promise.all(params);
  return res;
};
//删除数据面片
export const delDataPannel = (tag) => {
  return new Promise((resolve) => {
    Dandelion.RtAPI("getDataPannelList", { view: false }, (data) => {
      console.log(data);
      // Dandelion.RtAPI(
      //   "setDisplayDataPannel",
      //   {
      //     display: false,
      //     ids: data
      //       .filter((res) => res.tags.includes(tag))
      //       .map((res) => res.id),
      //   },
      //   (res2) => {
      //     resolve(res2);
      //   }
      // );
      for (let res of data) {
        if (!tag || res.tags.includes(tag)) {
          Dandelion.RtAPI("deleteDataPannel", { id: res.id }, () => {
            // console.log(res);
          });
        }
      }
      resolve(1);
    });
  });
};

export const getHighlightAreaList = () => {
  return new Promise((resolve) => {
    Dandelion.RtAPI("getHighlightAreaList", { view: false }, (res) => {
      resolve(res);
    });
  });
};

// Dandelion.RtAPI(
//   "setObjectTag",
//   {
//     id: "5-5ae6-6c3d-377e-45c8c39ca1",
//     tags: ["承包土地"],
//     type: 0,
//   },
//   (res) => {
//     console.log(res);
//   }
// );

//播放视频
export const playVideo = (params) => {
  return new Promise((resolve) => {
    Dandelion.RtAPI("setVideoPannelPlay", params, (res) => {
      resolve(res);
    });
  });
};
//获取视频列表
export const getVideoList = (params) => {
  return new Promise((resolve) => {
    Dandelion.RtAPI("getVideoPannelList", params, (res) => {
      resolve(res);
    });
  });
};

export const getPoiTemplate = (params) => {
  return new Promise((resolve) => {
    Dandelion.RtAPI("getPoiTemplate", params, (res) => {
      resolve(res);
    });
  });
};

export const setObjectTag = (params) => {
  return new Promise((resolve) => {
    Dandelion.RtAPI("setObjectTag", params, (res) => {
      resolve(res);
    });
  });
};
export const updateHighlightArea = (params) => {
  return new Promise((resolve) => {
    Dandelion.RtAPI("updateHighlightArea", params, (res) => {
      resolve(res);
    });
  });
};

export const videoTrack = (params) => {
  return new Promise((resolve) => {
    Dandelion.RtAPI("videoTrack", params, (res) => {
      resolve(res);
    });
  });
};

export const getObjectByTag = (params) => {
  return new Promise((resolve) => {
    Dandelion.RtAPI("getObjectList", params, (res) => {
      resolve(res);
    });
  });
};
