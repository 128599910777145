export const intersection = (arr1, arr2) => {
  // var arr1=[1,2,3,4,5,6,"a","b"]
  // var arr2=['a','b',1,2]
  const arr3 = arr1.filter((item) => {
    return arr2.includes(item);
  });
  return arr3;
  // console.log(arr3);
};

export const isIntersection = (arr1, arr2) => {
  // var arr1=[1,2,3,4,5,6,"a","b"]
  // var arr2=['a','b',1,2]
  const arr3 = arr1.filter((item) => {
    return arr2.includes(item);
  });
  return arr3 && arr3.length > 0 ? true : false;
  // console.log(arr3);
};
export const subtract = (arr1, arr2) => {
  const m = {};
  arr1.forEach(function (al) {
    m[al] = al;
  });
  arr2.forEach(function (bl) {
    delete m[bl];
  });
  return Object.keys(m);
};
